import React from 'react'
import { withAuthenticator } from '@aws-amplify/ui-react';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CommentIcon from '@material-ui/icons/Comment';
import AppsIcon from '@material-ui/icons/Apps';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';

import { Auth, Storage } from 'aws-amplify';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';
import PersonIcon from '@material-ui/icons/Person';
import awsconfig from './aws-exports';
import Amplify from 'aws-amplify';
import { APP_AWS_REGION, BUCKET_FILES ,BUCKET_EXTERNAL_VIP,BUCKET_EXTERNAL_DEUR} from './env';

Amplify.configure(awsconfig);

const useStyles = theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  myContent: {
    padding: theme.spacing(4, 2, 2),
  },
  grow: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  actionButtons: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },  

});


class App extends React.Component {
  
  state = { 
   isLoading: true,
   alertError:true,
   alertSucces:true,
   alertInfo:false,
   isAdmin:false,
   isLoadingAdmin: true,
   alertErrorAdmin:true,
   alertSuccesAdmin:true,
   alertInfoAdmin:false,
   isMBO:false
  
 }
 


  constructor(props){
    super(props);
    this.toggleBlocking = this.toggleBlocking.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      userInfo : {},
      blocking: false,
      blockingAdmin: false,
      listavalue:'R09'
    };
  }
  toggleBlocking() {
    this.setState({blocking: !this.state.blocking});
  }
  
  async componentDidMount() {
    try {
      const data = await Auth.currentUserPoolUser();
      let rol='';
      let alertInfo=false;
      let isVip=false;
      let isAdmin=false;
      let isMBO=false;
      let rolText='';
     
      
      for (var key in data.attributes) {
      if (data.attributes.hasOwnProperty(key)) {
          if(key == 'custom:rol'){
             rol=data.attributes[key];
             rol=rol.toUpperCase();
             rolText=rol;
            break;
          }
          //console.log("%c "+key + " = " + data.attributes[key]);
      }
    }
      

      if(rol == ''){
        alertInfo=true;
      }else if(rol=='ADMIN'){
        isAdmin=true;
      }
      
      const userInfo = { username: data.username,rol: rol, ...data.attributes,rolText:rolText };
      this.setState({userInfo: userInfo,alertInfo:alertInfo,isAdmin:isAdmin});
      //console.log("userInfo:",userInfo);
    } catch (err) { 
      console.log('error: ', err)
    }
  }
  
  componentWillUnmount() {
  }
  
  handleChange(event) {
    this.setState({listavalue: event.target.value});
   
  }
  
  onClickSignout = async event => {
   	try {
   	await Auth.signOut();
   	window.location.reload(false);
   	} catch (error) {
   	console.log('error signing out: ', error);
   	}
 	}
 	
 	
 	onChangeFileVip = async e =>{
 	  const currentTimeInMilliseconds=Date.now();
 	  const file = e.target.files[0];
 	  this.setState({ isLoadingAdmin: this.state.isLoadingAdmin?false:true,blockingAdmin: !this.state.blockingAdmin});
 	      
 	      if(this.state.listavalue == 'R09' ){
 	          Amplify.configure({
              Storage: {
                AWSS3: {
                    bucket: BUCKET_EXTERNAL_VIP, //REQUIRED -  Amazon S3 bucket
                    region: APP_AWS_REGION, //OPTIONAL -  Amazon service region
                }
              }
            });
 	        
 	      }else{
 	         	Amplify.configure({
              Storage: {
                AWSS3: {
                    bucket: BUCKET_EXTERNAL_DEUR, //REQUIRED -  Amazon S3 bucket
                    region: APP_AWS_REGION, //OPTIONAL -  Amazon service region
                }
              }
            });
 	        
 	      }
     	  

    
        try {
        
            const upload = await Storage.put(currentTimeInMilliseconds+"-"+file.name, file, {
                            resumable: true,
                            metadata: { email: String(this.state.userInfo.email)},
                            contentType: 'application/csv',
                            completeCallback: (event) => {
                                console.log(`Successfully uploaded ${event.key}`);
                            },
                            progressCallback: (progress) => {
                                console.log(`Uploaded: ${progress.loaded}/${progress.total}`,((progress.loaded/progress.total)*100)+'%');
                                this.setState({ isLoadingAdmin:true,
           	                                    blockingAdmin: true});
                            },
                            errorCallback: (err) => {
                                console.error('Unexpected error while uploading', err);
                                this.setState({ isLoadingAdmin: false,alertErrorAdmin:true,blockingAdmin: false});
                            }
                    }).then(result => {
                        console.log(result);
                        this.setState({ isLoadingAdmin: this.state.isLoadingAdmin?false:true,
       	                                alertSuccesAdmin:true,blockingAdmin: !this.state.blockingAdmin});
                  	    setTimeout(() => {
                          console.log('Interval triggered');
                                this.setState({
                   	                alertSuccesAdmin:false});
                   	                e.target.value = '';
                        }, 5000);
                     
                    }).catch(err => {
                        console.error("ERROR:",err);
                        this.setState({ isLoadingAdmin: false,
                                   	    alertErrorAdmin:true,blockingAdmin: false});
                      });
        } catch (error) {
          console.error("Error uploading file: ", error);
          this.setState({ isLoadingAdmin: false,
                     	    alertErrorAdmin:true,blockingAdmin: false});
        }
 	  
 	  
 	}
 	
  onChangeFile = async e => {
    const currentTimeInMilliseconds=Date.now();
    const file = e.target.files[0];
    
    //console.log("file.size:",file.size);
    
    if(file.size > 5200000){ //5MB
      alert("El tamaño supera el limite permitido de 400,000 lineas o 5Mb de informacion");
      e.target.value = '';
      return;
    }
    
    this.setState({ isLoading: this.state.isLoading?false:true,blocking: !this.state.blocking});
    

    Amplify.configure({
      Storage: {
        AWSS3: {
            bucket: BUCKET_FILES, //REQUIRED -  Amazon S3 bucket
            region: APP_AWS_REGION, //OPTIONAL -  Amazon service region
        }
      }
    });

    try {

        
        const upload = await Storage.put(currentTimeInMilliseconds+"-"+file.name, file, {
                        level: 'protected',
                        metadata: { email: String(this.state.userInfo.email) ,rol:String(this.state.userInfo.rol)},
                        resumable: true,
                        contentType: 'application/csv',
                        completeCallback: (event) => {
                            console.log(`Successfully uploaded ${event.key}`);
                        },
                        progressCallback: (progress) => {
                            console.log(`Uploaded: ${progress.loaded}/${progress.total}`,((progress.loaded/progress.total)*100)+'%');
                            this.setState({ isLoading:true,
       	                                    blocking: true});
                        },
                        errorCallback: (err) => {
                            console.error('Unexpected error while uploading', err);
                            this.setState({ isLoading: false,alertError:true,blocking: false});
                        }
                }).then(result => {
                    console.log(result);
                    this.setState({ isLoading: this.state.isLoading?false:true,
                                     alertSucces:true,blocking: !this.state.blocking});
              	    setTimeout(() => {
                      console.log('Interval triggered');
                            this.setState({
               	                alertSucces:false});
               	                e.target.value = '';
                    }, 5000);
                 
                }).catch(err => {
                    console.error("ERROR:",err);
                    this.setState({ isLoading: false,
                               	    alertError:true,blocking: false});
                  });
                  
    } catch (error) {
      console.error("Error uploading file: ", error);
      this.setState({ isLoading: false,
                 	    alertError:true,blocking: false});
    }
    
    
    
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
              <AppsIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
               <img src={process.env.PUBLIC_URL + 'telcel-logo2.png'} alt="logo"  width="100px" /> 
            </Typography>
             <PersonIcon/> {this.state.userInfo.username}<br/> {this.state.userInfo.rolText}
            <Button color="inherit" onClick={this.onClickSignout} title="Cerrar Sesión">
              <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                <ExitToAppIcon />
              </IconButton>
            </Button>
          </Toolbar>
        </AppBar>
        
        <Container className={classes.myContent}>
        <div className={classes.root}>
          <BlockUi tag="div" blocking={this.state.blocking}>
            <Typography variant="h6" className={classes.title}>
              <FormatListNumberedIcon />Filtrar archivo de listas negras
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                
                {!this.state.alertInfo &&<p><b><ContactSupportIcon /> INSTRUCCIONES:</b> Favor de seleccionar un archivo .csv, sin encabezados con la lista de la lineas a filtrar.</p>}
                {this.state.isLoading && <CircularProgress />}
                {this.state.alertError && <Alert severity="error">Error al cargar archivo!</Alert>}
                {this.state.alertSucces && <Alert severity="success">Exito al cargar el archivo! En breve te llegara un correo</Alert>}
                {this.state.alertInfo && <p><b><ContactSupportIcon /> INSTRUCCIONES:</b></p>}
                {this.state.alertInfo && 
                                        <Alert severity="warning">
                                          Favor de enviar correo a Servicios Campañas &nbsp;  
                                         <a href="mailto:servicios.camp@telcel.com?Subject=Solicitud%20de%20perfil%20acceso%20portal%20Listas%20negras">servicios.camp@telcel.com </a>
                                          ,&nbsp; indicando la región a la que perteneces y el perfil.
                                          <p>Perfiles del sistema</p> 
                                                <ul> 
                                                   	<li><b>Listas</b> Filtrado de listas de líneas</li> 
                                                   	<li><b>ADMIN</b> Filtrado listas de líneas, así como
                                                        también actualización de listados de VIP. </li> 
                                                </ul>
                                          </Alert>}
                {!this.state.alertInfo && <input
                              type="file" accept=".csv" 
                              onChange={this.onChangeFile}
                          />}
                <br/>
                <a href="docs/ManualOperacionPortalLNv.pdf" target="_blank" title="Manual de operación" class="liga">
                      Ver manual
                   </a> 
  
                </Paper>
              </Grid>
            </Grid>
          </BlockUi>
         {this.state.isAdmin && 
          <BlockUi tag="div" blocking={this.state.blockingAdmin}>
            <Typography variant="h6" className={classes.title}>
              <AddToPhotosIcon /> Carga de listas VIP
            </Typography>
            

          
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                <p><b><ContactSupportIcon /> INSTRUCCIONES:</b>Favor de seleccionar un archivo .csv,sin encabezados con la lista de lineas negras VIP a cargar.</p> 
                {this.state.isLoadingAdmin && <CircularProgress />}
                {this.state.alertErrorAdmin && <Alert severity="error">Error al cargar archivo!</Alert>}
                {this.state.alertSuccesAdmin && <Alert severity="success">Exito al cargar el archivo! En cuanto termine el proceso llegara un correo.</Alert>}
                
                <select value={this.state.listavalue} onChange={this.handleChange} style={{width: '132px'}}>
                  <option value="R09">R09</option>
                  <option value="DEUR">DEUR</option>
                </select>
                <br/>
                <input
                              type="file" accept=".csv" 
                              onChange={this.onChangeFileVip}
                          />
                <br/>
                <a href="docs/ManualOperacionPortalLNvVIP.pdf" target="_blank" title="Manual de operación" class="liga">
                      Ver manual
                   </a>                   
                </Paper>
              </Grid>
            </Grid>
          </BlockUi>
          }
          
        </div>
        </Container>
        
      </div>
        
    );
  }
}
  

export default withAuthenticator(withStyles(useStyles)(App));